import React, { Component } from 'react'

import SEO from '../components/SEO/SEO'
import Layout from '../Layout/Layout'
import Hero from '../components/Hero/Hero'
import Header from '../components/Header/Header'
import Paragraph from '../components/Paragraph/Paragraph'
import Container from '../components/Container/Container'

import Image8 from '../assets/images/nozycowka.jpg'
import Mapa from '../assets/images/mapa.png'

class KontaktPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Kontakt | Skontaktuj się z nami aby wynająć zwyżkę wraz z operatorem"
          keywords={['kontakt']}
        />
        <Hero image={Image8}>
          <Header>Kontakt</Header>
        </Hero>
        <Header isBig>Skontaktuj się</Header>
        <Container columns={2}>
          <div>
            <Paragraph>
              "Kamel" Usługi zwyżką
              <br />
              Szymon Marut
              <br />
              ul. Polna 18
              <br />
              37-433 Bojanów
            </Paragraph>
            <Paragraph>mail: szymonmarut@wp.pl</Paragraph>
            <Paragraph>tel: +48 788 716 154</Paragraph>
            <Paragraph>www: kamelzwyzka.pl</Paragraph>
          </div>
          <div>
            <img
              src={Mapa}
              alt="Mapa Polski z zaznaczonymi miejscowościami: Rzeszów, Stalowa Wola, Tarnobrzeg, Mielec, Sandomierz, Nisko, Nowa Dęba..."
            />
          </div>
        </Container>
      </Layout>
    )
  }
}

export default KontaktPage
